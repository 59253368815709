import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CitadelStylesModule } from '@citadel/styles';
import { CitadelCoreModule } from '@citadel/core';
import { CitadelControlsModule } from '@citadel/controls';
import { CitadelCitazenModule } from '@citadel/citazen';
import { ToastrModule } from 'ngx-toastr';
import { AppHomeComponent } from './app-home/app-home.component';
import { CitadelMszService } from '../../projects/citadel-msz/src/lib/services/citadel-msz.service'
import { CitadelWebClientService } from '../../projects/citadel-msz/src/lib/citadel-webadmin/citadel-web-client.service';
import { MSZNotificationService } from '../../projects/citadel-msz/src/lib/services/mszNotification.service';
import { CitadelPipesModule } from '@citadel/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MszNotificationControlComponent } from './notification-control/notification-control.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

@NgModule({
  declarations: [
    AppComponent,
    AppHomeComponent,
    MszNotificationControlComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CitadelStylesModule,
    CitadelCoreModule.forRoot({
      isProduction: environment.production, 
      loggingOptions: {
        authentication: false,
        silentRenew: false,
      },
      noResetUrls: [
        'citazen.co.za/api/msz/Structure/getNotifications',
        'citazen.co.za/api/citazen-config/Maintenance/',
        'citazen.co.za/connect/authorize',
        '/assets/silent-renew.html',
        '/ngsw.json',
      ]
    }),
    CitadelControlsModule,
    CitadelPipesModule,
    FormsModule,
    ReactiveFormsModule,
    CitadelCitazenModule,
    ToastrModule.forRoot({ progressBar: true }),
    NgcCookieConsentModule.forRoot({ cookie: { domain: window.location.host }, }),
  ],
  providers: [CitadelMszService, CitadelWebClientService, MSZNotificationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
