<div class="relative-container">
    <div *ngIf="timeToSessionTimeout" class="timeout-warning sign-in-page">
        <div class="sign-in-container">
            <div>
                <p>Due to inactivity and to help protect your account, you will be signed out in {{timeToSessionTimeout}} seconds</p>
                <citadel-button [primary]="true" (click)="clearSessionTimeout()">Remain Signed In</citadel-button>
            </div>
        </div>
    </div>
    <citazen-home-page></citazen-home-page>
</div>