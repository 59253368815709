import { Injectable } from '@angular/core';
import { CitadelWebService, CitadelUserService } from '@citadel/core';
import { ImpersonationLogModel } from '../models/ImpersonationLogModel';

@Injectable({
  providedIn: 'root'
})
export class CitadelMszImpersonationService {

  public isImpersonating: boolean = false;

  constructor(private userServ: CitadelUserService, private citadelWebService: CitadelWebService) { }

  SaveImpersonationLog(model:ImpersonationLogModel): any{
    return new Promise<any>(async (resolve, reject) => {
      this.citadelWebService.post('Msz.ImpersonationLog/saveImpersonationLog', model).subscribe((result: any) => {
        resolve(true);
      });
    });
  }

  GetImpersonationLog(userID:string): any {
    return new Promise<ImpersonationLogModel[]>(async (resolve, reject) => {
      this.citadelWebService.get('Msz.ImpersonationLog/getImpersonationLog', { userID: userID}).subscribe((result: ImpersonationLogModel[]) => {
        resolve(result);
      });
    });
  }
 
}
