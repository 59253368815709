import { Component } from '@angular/core';
import { CitadelUserService, CitadelAuthService } from '@citadel/core';

@Component({
  selector: 'clcapp-app-home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.scss']
})
export class AppHomeComponent {
  timeToSessionTimeout = undefined;
  timeoutInterval = undefined;

  constructor(private auth: CitadelAuthService, private user: CitadelUserService) { }

  ngOnInit() {
    this.user.user.subscribe(userValue => {
      if (userValue.hasPermission('Rule Automatic Sign-out')) {
        this.auth.sessionTimeout = 1800; // 30 minutes
        this.auth.getSessionTimeoutWarning().subscribe(count => {
          this.warnOfSessionTimeout(count);
        });
      }
    });
  }
  warnOfSessionTimeout(remaining) {

    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
    
    this.timeoutInterval = setInterval(() => this.timeToSessionTimeout = remaining--, 1000);
  }
  clearSessionTimeout() {
    this.timeToSessionTimeout = undefined;
    clearInterval(this.timeoutInterval);
    this.auth.restartSessionTimeout();
  }
}
