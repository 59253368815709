<div *ngIf="!authenticationHasBeenChecked" class="sign-in-page">
    <div class="sign-in-container">Please wait while we check your credentials...</div>
    <div class="loader-linear">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<router-outlet *ngIf="authenticationHasBeenChecked"></router-outlet>
<ng-template #user><citazen-user></citazen-user></ng-template>
<ng-template #menu><citazen-menu></citazen-menu></ng-template>
<ng-template #notification><citazen-notifications></citazen-notifications></ng-template>


<!--
<import-citadel-styles></import-citadel-styles>
<citadel-app-frame [headerSections]="citadelHeaderSection" [designMode]="modeDesign">
    <div app-slot="header" class="clientNameHeader">My Secure Zone <span> {{this.clientSelected}}</span> </div>
    <div app-slot="content" class="routerHeader">
        <router-outlet></router-outlet>
    </div>
</citadel-app-frame>

<ng-template #searchTemplate>
    <div class="ClientSearchClass">
        <citadel-form-section [heading]="'Search Client'">
            <citadel-autocomplete [data]="testDataClients" [displayField]="'clientName'" [resultField]="'clientID'"
                (valueChanged)="clientChanged($event)" drawer-slot="header"></citadel-autocomplete>
        </citadel-form-section>

    </div>
</ng-template>

<ng-template #menuTemplate>
    <citadel-full-screen-menu [menu]="userMenu.children"></citadel-full-screen-menu>
</ng-template>

<ng-template #notificationTemplate>

    <div class="NotificationClass">

        <citadel-form-section [heading]="'Notifications'">

            <ng-container
                *ngIf="mszNotificationServ.notifications.cache != undefined && mszNotificationServ.notifications.cache.length > 0">
    
                <table class="table">
                    <thead>
                        <td>Description</td>
                        <td>Status</td>
                    </thead>
                    <tbody>
                        <tr *ngFor="let value of mszNotificationServ.notifications.cache">
                            <td>{{value.description}}</td>
                            <td>
                                <br>
                                <ng-container *ngIf="value.type == 0">
                                    <span><i class="badge badge-info"
                                            (click)="setNotificationStatus(value)">{{value.typeDesc}}</i></span>
                                </ng-container>
                                <ng-container *ngIf="value.type == 1">
                                    <span><i class="badge badge-success">{{value.typeDesc}}</i></span>
                                </ng-container>
    
                            </td>
                        </tr>
    
                    </tbody>
                </table>
            </ng-container>
    
        </citadel-form-section>

    </div>

</ng-template>
-->