import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { NotificationModel } from '../models/NotificationModel';

@Injectable({
  providedIn: 'root'
})
export class MSZNotificationService {

  public notifications: {
    cache:NotificationModel[],
    sub: BehaviorSubject<NotificationModel[]>,
    obs: Observable<NotificationModel[]>

  } = {
    cache:undefined,
      sub: undefined,
      obs: undefined
    };


  constructor() {

    this.initialize();

  }

  initialize() {
    if (this.notifications.obs == undefined) {
      this.notifications.cache = []; 
      this.notifications.sub = new BehaviorSubject<NotificationModel[]>(this.notifications.cache);
      this.notifications.obs = this.notifications.sub.asObservable();
    }
  }


  createNotification(notificationObj: NotificationModel) {
    this.initialize();
    this.notifications.cache.push(notificationObj);
    this.notifications.sub.next(this.notifications.cache);
  }


  updateNotification(notificationObj: NotificationModel) {
    this.initialize();

    this.notifications.cache.forEach(element => {
      if (element.id == notificationObj.id) {
        element = notificationObj;
      }
    });
    this.notifications.sub.next(this.notifications.cache);
  }





}
