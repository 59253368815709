export enum enumLogEventLevel{
    Verbose = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Fatal = 5
}

export enum enumLogAction{
    Log_in = 0
    ,Forgot_password_reset = 1
    ,Password_change = 2
    ,Generated_Quarterly_Report = 3
    ,Investment_Totals_Generated_Valuation_Report_HTML = 4
    ,Investment_Totals_Generated_Valuation_Report_PDF = 5
    ,Investment_Totals_Generated_Valuation_Report_Excel = 6
    ,Username_change = 7
    ,Client_document_downloaded = 8
    ,Client_registered = 9
    ,Client_activated_by_staff = 10
    ,Client_deactivated_by_staff = 11
    ,Citadel_Generated_Valuation_Report_HTML = 12
    ,Citadel_Generated_Valuation_Report_PDF = 13
    ,Citadel_Generated_Valuation_Report_Excel = 14
    ,External_Generated_Valuation_Report_HTML = 15
    ,External_Generated_Valuation_Report_PDF = 16
    ,External_Generated_Valuation_Report_Excel = 17
    ,Account_Locked = 18
    ,Navigation = 19
    ,Client_Search = 20
    ,Login_Confirmation_outstanding = 21
    ,Password_Reset = 22
    ,Message_sent = 23
    ,Replied_to_message = 24
    ,Push_notification_received = 25
    ,MDD_downloaded = 26
    ,Created_scenario_in_financial_planner = 27
    ,Generate_statement = 28
    ,Notification_status_change = 29
    ,Read_Market_Comments = 30
    ,Code_trackinglog = 31
    ,Generate_Annual = 32
    ,Otp_Change = 33
    ,Change_Username = 34
    ,Otp_Add = 35
    ,Add_Username = 36
    ,Update_Personal_details = 37
    ,View_Advisor = 38
    ,View_Support = 39
    ,Upload_Document = 40
    ,Sign_Document = 41
    ,Request_Callback = 45
    ,Generate_PWL_Report = 46
}

export class ActivityLogModel
{
    public LogLevel: enumLogEventLevel;
    public Comments: string;
    public Action: enumLogAction;
    public ClientID: string;
    public Exception: string;
}