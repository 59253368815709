import { Injectable } from '@angular/core';
import { clientModel } from './models/clientModel';
import { Observable, BehaviorSubject, of, mergeMap } from 'rxjs';
import { CitadelWebService, CitadelUserService, CitadelMenuService, CitadelNotificationService, IAuthUser, CitadelSilentImpersonationService, CitadelMenuItem } from '@citadel/core';
import { CitadelNotificationHeaderSection } from '@citadel/controls';
import { ComponentModel, MSZComponentModel } from './models/MSZComponentModel';
import { CitadelMszImpersonationService } from '../services/citadel-msz-impersonation.service';
import { ImpersonationLogModel } from '../models/ImpersonationLogModel';
import { CitadelUserActionsService } from '@citadel/citazen';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CitadelWebClientService {

  public loadClientAccountsComplete: Observable<Boolean>;
  private loadClientAccountsCompleteSubject = new BehaviorSubject<Boolean>(false);

  public loadClientAccountsClientComplete: Observable<string>;
  private loadClientAccountsClientCompleteSubject = new BehaviorSubject<string>('');

  public loadAuthUserComplete: Observable<any>;
  private loadAuthUserCompleteSubject = new BehaviorSubject<any>([]);

  public authPermission: {
    cache: any[],
    sub: BehaviorSubject<any[]>,
    obs: Observable<any[]>
  } = {
      cache: undefined,
      sub: undefined,
      obs: undefined
    };



  public clientComponentList: MSZComponentModel[] = [];
  public clientComponentSelectedList: MSZComponentModel[] = [];
  public clientComponentListSP: ComponentModel[] = [];
  public clientComponentSelectedListSP: ComponentModel[] = [];



  // public clientInfo: {
  //   cache: clientModel,
  //   sub: BehaviorSubject<clientModel>,
  //   obs: Observable<clientModel>
  // } = {
  //     cache: undefined,
  //     sub: undefined,
  //     obs: undefined
  //   };


  public clientNotifications: {
    cache: any[],
    cacheCategories: any[],
    sub: BehaviorSubject<any[]>,
    obs: Observable<any[]>
  } = {
      cache: undefined,
      cacheCategories: undefined,
      sub: undefined,
      obs: undefined
    };




  public crmClients: {
    cache: any,
    sub: BehaviorSubject<any>,
    obs: Observable<any>
  } = {
      cache: undefined,
      sub: undefined,
      obs: undefined
    };


  public searchedClients: {
    cache: any,
    sub: BehaviorSubject<any>,
    obs: Observable<any>
  } = {
      cache: undefined,
      sub: undefined,
      obs: undefined
    };

  public authUser: {
    cache: IAuthUser,
    sub: BehaviorSubject<IAuthUser>,
    obs: Observable<IAuthUser>
  } = {
      cache: undefined,
      sub: undefined,
      obs: undefined
    };

  public isInCitazen: boolean = false;
  private sub: any;

  citadelMenuItemObservable: Observable<CitadelMenuItem[]>;
  public firstTimeMenu: boolean = true;

  constructor(private silentImpersonationService: CitadelSilentImpersonationService, private router: Router, private citadelUserActionsService: CitadelUserActionsService, private mszImpersonationService: CitadelMszImpersonationService, private citadelMenuService: CitadelMenuService, private citadelNotificationService: CitadelNotificationService, private webService: CitadelWebService, private notificationSection: CitadelNotificationHeaderSection, private userServ: CitadelUserService) {

    this.citadelMenuItemObservable = this.citadelMenuService.getMenu();

    this.loadClientAccountsComplete = this.loadClientAccountsCompleteSubject.asObservable();
    this.loadAuthUserComplete = this.loadAuthUserCompleteSubject.asObservable();

    let clientObj: clientModel = new clientModel();

    clientObj.name = 'NO CLIENT SELECTED';
    clientObj.id = '';
    clientObj.userName = '';

    //this.setClient(clientObj);
    this.setCRMClients("");
    this.setsearchedClients("");

    this.clientNotifications.cache = [];
    this.clientNotifications.sub = new BehaviorSubject<any[]>(this.clientNotifications.cache);
    this.clientNotifications.obs = this.clientNotifications.sub.asObservable();

    this.setNotificationWatch();

    if (!this.authUser.obs) {
      this.authUser.sub = new BehaviorSubject<IAuthUser>(this.authUser.cache);
      this.authUser.obs = this.authUser.sub.asObservable();
    }

    this.userServ.user.subscribe(value => {
      if (value) {
        this.authUser.cache = value;
        this.authUser.sub = new BehaviorSubject<IAuthUser>(this.authUser.cache);
        this.authUser.obs = this.authUser.sub.asObservable();
      }
    })
  }







  setNotificationWatch() {
    this.clientNotifications.obs.subscribe(items => {
      if (items && items.length > 0) {
        if (items.find(item => item.NotificationCategoryL != 9 && item.Description != '')) {
          this.notificationSection.count = items.filter(value => value.NotificationCategoryL != 9 && value.Description != '').length;
        } else {
          this.notificationSection.count = 0;
        }
      } else {
        this.notificationSection.count = 0;
      }
    });
  }

  //NOTIFICATIONS
  // switchNotificationUser(clientID: string) {
  //   this.clearClientNotifications();
  //   if (clientID != "") {
  //     this.getClientNotifications(clientID);
  //   } else {
  //     let items: any[] = [];
  //     this.clientNotifications.sub.next(items);
  //   }
  // }

  resetImpersonationLabel() {

    this.userServ.otherImpersonationLabel.sub.next('');
    this.userServ.otherImpersonationLabel.obs = this.userServ.otherImpersonationLabel.sub;
  }

  setCRMClients(clients: any) {

    if (!this.crmClients.obs) {
      this.crmClients.sub = new BehaviorSubject<string>(this.crmClients.cache);
      this.crmClients.obs = this.crmClients.sub.asObservable();
    }

    this.crmClients.cache = clients;
    this.crmClients.sub.next(this.crmClients.cache);
    this.crmClients.obs = this.crmClients.sub;

  }

  setsearchedClients(clients: any) {
    if (!this.searchedClients.obs) {
      this.searchedClients.sub = new BehaviorSubject<string>(this.searchedClients.cache);
      this.searchedClients.obs = this.searchedClients.sub.asObservable();
    }

    this.searchedClients.cache = clients;
    this.searchedClients.sub.next(this.searchedClients.cache);
    this.searchedClients.obs = this.searchedClients.sub;
  }

  loadClientsAndAccounts(clientID: string): any {
    return new Promise<any>(async (resolve, reject) => {


      if ((this.loadClientAccountsClientCompleteSubject.getValue() != clientID && clientID != '') || this.loadClientAccountsCompleteSubject.getValue() == false) {


        this.loadClientAccountsClientCompleteSubject.next(clientID);
        this.loadClientAccountsClientComplete = this.loadClientAccountsClientCompleteSubject.asObservable();

        this.loadClientAccountsCompleteSubject.next(false);
        this.loadClientAccountsComplete = this.loadClientAccountsCompleteSubject.asObservable();
        this.webService.get('Msz.Structure/loadRelatedAccounts', { clientID: clientID }).subscribe((result: any) => {
          this.loadClientAccountsCompleteSubject.next(true);
          this.loadClientAccountsComplete = this.loadClientAccountsCompleteSubject.asObservable();
          resolve(result);
        });
      } else {
        resolve(true);
      }
    });
  }


  clearClientNotifications() {
    this.clientNotifications.cache = undefined;
    this.clientNotifications.cacheCategories = undefined;
  }

  archiveClientNotification(notificationID: string) {
    return new Promise<any>(async (resolve, reject) => {
      this.webService.get('Msz.Structure/archiveNotification', { notificationID: notificationID }).subscribe((result: any) => {
        resolve(result);
      });
    });
  }

  loadAuthPermissions(): any {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.authPermission.obs) {
        this.authPermission.sub = new BehaviorSubject<any[]>(this.authPermission.cache);
        this.authPermission.obs = this.authPermission.sub.asObservable();
      }
      if (this.authPermission.cache == undefined) {
        this.webService.get('WA.WebProfile/loadAuthPermissions').subscribe((result: any) => {
          this.authPermission.cache = [];
          this.authPermission.cache = result;
          this.authPermission.sub.next(this.authPermission.cache);
          resolve(this.authPermission.cache);
        });
      }
      else {
        resolve(this.authPermission.cache);
      }
    });
  }

  searchClients(searchWords: string[]): Observable<any[]> {
    if (this.authUser.cache != undefined && this.authUser.cache.id != "") {
      return this.webService.post('Entity.Integration.Client', { partyID: this.authUser.cache.id, sid: 0, words: searchWords, applySharing: true, maxRows: 100 });
    } else {
      return of([]);
    }
  }

  getCRMClients(clientID: string): any {
    if (clientID != undefined && clientID != null && clientID != "") {
      return new Promise<any>(async (resolve, reject) => {
        this.webService.get('Msz.Structure/getCRMClients').subscribe((result: any) => {
          this.crmClients.sub.next(result);
          this.crmClients.obs = this.crmClients.sub;
          resolve(result);
        });
      });
    }
    else {
      return new Promise<any>(async (resolve, reject) => {
        this.webService.get('Msz.Structure/getCRMClients').subscribe((result: any) => {
          this.crmClients.sub.next(result);
          this.crmClients.obs = this.crmClients.sub;
          resolve(result);
        });
      });
    }
  }

  // getAuthUSer(): any {
  //   return new Promise<any>(async (resolve, reject) => {
  //     this.userServ.user
  //     this.webService.get('Msz.Structure/getAuthUser').subscribe((result: any) => {
  //       this.loadAuthUserCompleteSubject.next(result);
  //       this.loadAuthUserComplete = this.loadAuthUserCompleteSubject.asObservable();
  //       resolve(result);
  //     });
  //   });
  // }

  public clearClientComponentListCache() {
    this.clientComponentList = [];
    this.clientComponentSelectedList = [];
  }
  public clearClientComponentListCacheSP() {
    this.clientComponentListSP = [];
    this.clientComponentSelectedListSP = [];
  }

  isComponentValid(permissionCode: string, accountID: string, primaryClientID: string): boolean {
    let result = false;


    if (this.clientComponentSelectedList.length > 0) {
      if (accountID != "" && primaryClientID != "") {
        if (primaryClientID.toLowerCase() == this.authUser.cache.id.toLowerCase()) {
          if (this.clientComponentSelectedList.findIndex(component => component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
            if (this.clientComponentSelectedList.find(value => value.permissionCode.toLowerCase() == permissionCode.toLowerCase()).validPrimaryAccountsArray.includes(accountID.toUpperCase())) {
              result = true;
            }
          }
        }
        else if (this.clientComponentSelectedList.findIndex(component => component.accountID != undefined && component.accountID.toLowerCase() == accountID.toLowerCase() && component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
          result = true;
        }
        else {
          if (permissionCode.toLowerCase() != "quarterly reports" && permissionCode.toLowerCase() != "annual reports" && this.clientComponentSelectedList.findIndex(component => component.clientID.toLowerCase() == this.authUser.cache.id.toLowerCase() && component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
            if (this.clientComponentSelectedList.find(value => value.permissionCode.toLowerCase() == permissionCode.toLowerCase()).validPrimaryAccountsArray.includes(accountID.toUpperCase())) {
              result = true;
            }
          }
        }
      }
      else {
        if (this.clientComponentSelectedList.findIndex(component => component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
          result = true;
        }
      }
    }
    return result;
  }

  isComponentValidCRM(permissionCode: string, accountID: string, primaryClientID: string, crmClientID: string): boolean {
    let result = false;


    if (this.clientComponentSelectedList.length > 0) {
      if (accountID != "" && primaryClientID != "") {
        if (primaryClientID.toLowerCase() == crmClientID.toLowerCase()) {
          if (this.clientComponentSelectedList.findIndex(component => component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
            if (this.clientComponentSelectedList.find(value => value.permissionCode.toLowerCase() == permissionCode.toLowerCase()).validPrimaryAccountsArray.includes(accountID.toUpperCase())) {
              result = true;
            }
          }
        }
        else if (this.clientComponentSelectedList.findIndex(component => component.accountID != undefined && component.accountID.toLowerCase() == accountID.toLowerCase() && component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
          result = true;
        }
        else {
          if (permissionCode.toLowerCase() != "quarterly reports" && permissionCode.toLowerCase() != "annual reports" && this.clientComponentSelectedList.findIndex(component => component.clientID.toLowerCase() == crmClientID.toLowerCase() && component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
            if (this.clientComponentSelectedList.find(value => value.permissionCode.toLowerCase() == permissionCode.toLowerCase()).validPrimaryAccountsArray.includes(accountID.toUpperCase())) {
              result = true;
            }
          }
        }
      }
      else {
        if (this.clientComponentSelectedList.findIndex(component => component.permissionCode.toLowerCase() == permissionCode.toLowerCase()) > -1) {
          result = true;
        }
      }
    }
    return result;
  }


  saveComponentList(): any {
    return new Promise<any>(async (resolve, reject) => {
      this.webService.post('WA.WebProfile/storeComponentList', this.clientComponentList).subscribe((result: any) => {
        resolve(true);
      });
    });
  }


  checkInCitazen(): any {
    return new Promise<any>(async (resolve, reject) => {


      //if (this.firstTimeMenu){  always do this check as isInCitazen resets when navigating to other screen and back
      await this.GetMenuObs();
      //}

      resolve(this.isInCitazen);
      // this.isInCitazen = true;
      // let currentMenu: any = await this.citadelMenuService.getMenu();

      // for (const item of currentMenu) {
      //   if (item.length > 0) {
      //     if (item[0].application.id.toLowerCase() == '38c8f8a8-7337-ea11-a1cc-2818786d9362') {
      //       this.isInCitazen = true;
      //     }
      //   }
      // });

      // resolve(this.isInCitazen);
    });
  }



  GetMenuObs(): any {
    return new Promise<any>(async (resolve, reject) => {

      this.citadelMenuItemObservable.subscribe((currentMenu: any) => {
        if (currentMenu && currentMenu.length > 0) {
          for (const item of currentMenu) {
            if (item.length && item.length > 0) {
              if (item[0].application.id.toLowerCase() == '38c8f8a8-7337-ea11-a1cc-2818786d9362') {
                this.isInCitazen = true;
              }
            } else if (item.application) {
              if (item.application.id.toLowerCase() == '38c8f8a8-7337-ea11-a1cc-2818786d9362') {
                this.isInCitazen = true;
              }
            }
          };
          this.firstTimeMenu = false;
          resolve(true);
        }
      });
    });
  }

  loadComponentListObs(ClientID: string): any {

    return this.webService.get('WA.WebProfile/loadComponentList', { clientID: ClientID, onlySelected: false }).pipe(
      mergeMap((summary: any) => {
      const clientComponentList:any[] = [];
      for (const element of summary) {
        let component: MSZComponentModel = element;
        if (component.validPrimaryAccounts != '') {
          component.validPrimaryAccountsArray = component.validPrimaryAccounts.split(',');
        }
        else {
          component.validPrimaryAccountsArray = [];
        }
        clientComponentList.push(component);
      };
      return of(clientComponentList.filter(value => value.selected == true));
    }));


  }


  loadComponentList(ClientID: string, useCache: boolean): any {
    return new Promise<any>(async (resolve, reject) => {
      if (this.authUser.cache.id == "" && (ClientID == undefined || ClientID == "")) {
        this.citadelNotificationService.error("No client selected");
        reject(false);
      }
      if (ClientID == undefined || ClientID == "") {
        ClientID = this.authUser.cache.id;
      }
      if (!useCache) {
        this.clientComponentSelectedList = [];
        this.clientComponentList = [];
      }
      if (!useCache || this.clientComponentList.length == 0 || this.clientComponentSelectedList.length == 0) {
        ClientID = ClientID == undefined ? "00000000-0000-0000-0000-000000000000" : ClientID;
        this.webService.get('WA.WebProfile/loadComponentList', { clientID: ClientID, onlySelected: false }).subscribe((summary: any) => {
          this.clientComponentList = [];
          for (const element of summary) {
            let component: MSZComponentModel = element;
            if (component.validPrimaryAccounts != '') {
              component.validPrimaryAccountsArray = component.validPrimaryAccounts.split(',');
            }
            else {
              component.validPrimaryAccountsArray = [];
            }
            this.clientComponentList.push(component);
          };
          this.clientComponentSelectedList = [];
          this.clientComponentSelectedList = this.clientComponentList.filter(value => value.selected == true);
          resolve(this.clientComponentSelectedList);
        });
      }
      else {
        resolve(this.clientComponentSelectedList);
      }
    });
  }

  loadComponentListSP(ClientID: string, useCache: boolean): any {
    return new Promise<any>(async (resolve, reject) => {
      if (this.authUser.cache.id == "" && (ClientID == undefined || ClientID == "")) {
        this.citadelNotificationService.error("No client selected");
        reject(false);
      }
      if (ClientID == undefined || ClientID == "") {
        ClientID = this.authUser.cache.id;
      }
      if (!useCache) {
        this.clientComponentSelectedListSP = [];
        this.clientComponentListSP = [];
      }
      if (!useCache || this.clientComponentListSP.length == 0 || this.clientComponentSelectedListSP.length == 0) {
        ClientID = ClientID == undefined ? "00000000-0000-0000-0000-000000000000" : ClientID;
        this.webService.get('WA.WebProfile/loadComponentListSP', { clientID: ClientID, onlySelected: false }).subscribe((summary: any) => {
          this.clientComponentListSP = [];
          for (const element of summary) {
            let component: ComponentModel = element;
            if (component.validPrimaryAccounts != '') {
              component.validPrimaryAccountsArray = component.validPrimaryAccounts.split(',');
            }
            else {
              component.validPrimaryAccountsArray = [];
            }
            this.clientComponentListSP.push(component);
          };
          this.clientComponentSelectedListSP = [];
          this.clientComponentSelectedListSP = this.clientComponentListSP.filter(value => value.selected == true);
          resolve(this.clientComponentSelectedListSP);
        });
      }
      else {
        resolve(this.clientComponentSelectedListSP);
      }
    });
  }

  loadComponentListCRM(ClientID: string): any {
    return new Promise<any>(async (resolve, reject) => {
      if ((ClientID == undefined || ClientID == "")) {
        this.citadelNotificationService.error("No client selected");
        reject(false);
      }

      if (this.clientComponentList.length == 0 || this.clientComponentSelectedList.length == 0) {
        ClientID = ClientID == undefined ? "00000000-0000-0000-0000-000000000000" : ClientID;
        this.webService.get('WA.WebProfile/loadComponentList', { clientID: ClientID, onlySelected: false }).subscribe((summary: any) => {
          this.clientComponentList = [];
          for (const element of summary) {
            let component: MSZComponentModel = element;
            if (component.validPrimaryAccounts != '') {
              component.validPrimaryAccountsArray = component.validPrimaryAccounts.split(',');
            }
            else {
              component.validPrimaryAccountsArray = [];
            }
            this.clientComponentList.push(component);
          };
          this.clientComponentSelectedList = [];
          this.clientComponentSelectedList = this.clientComponentList.filter(value => value.selected == true);
          resolve(this.clientComponentSelectedList);
        });
      }
      else {
        resolve(this.clientComponentSelectedList);
      }
    });
  }



  async clientChanged(event: any, impersonateClient: string, switchBack: boolean) {
    //let clientObj: clientModel = new clientModel();

    // if (impersonateClient == '' || switchBack) {
    //   clientObj.name = event.name;
    //   clientObj.id = event.id;
    //   clientObj.userName = event.userName;

    // }
    // else {
    //   clientObj.name = event.clientName;
    //   clientObj.id = event.id;
    //   clientObj.userName = event.eMailAddress;
    // }

    this.isInCitazen = false;

    let currentMenu: any = await this.citadelMenuService.getMenu();

    currentMenu.forEach(item => {
      if (item.length > 0) {
        if (item[0].application.id.toLowerCase() == '38c8f8a8-7337-ea11-a1cc-2818786d9362') {
          this.isInCitazen = true;
        }
      }
    });

    if (impersonateClient != "") {
      if (this.authUser.cache && this.authUser.cache.impersonator) {
        let impersonation: ImpersonationLogModel = new ImpersonationLogModel;
        impersonation.ID = undefined;
        impersonation.UserID = this.authUser.cache.impersonator.id;
        impersonation.ClientID = this.authUser.cache.id;
        impersonation.StartTime = new Date();
        impersonation.EndTime = undefined
        this.mszImpersonationService.isImpersonating = true;
        await this.mszImpersonationService.SaveImpersonationLog(impersonation);
        this.citadelUserActionsService.clear();
        if (impersonateClient != "") {
          this.citadelUserActionsService.addLink('Switch back from ' + impersonateClient, ['/MSZ/switchback/' + this.authUser.cache.impersonator.id]);
        }
        else {
          this.citadelUserActionsService.addLink('Switch back from impersonation', ['/MSZ/switchback/' + this.authUser.cache.impersonator.id]);
        }
        //NOTIFICATIONS this.switchNotificationUser(this.authUser.cache.id);
      }
    }
    else if (switchBack) {
      this.resetImpersonationLabel();
      if (this.authUser.cache) {
        let impersonationLog = await this.mszImpersonationService.GetImpersonationLog(this.authUser.cache.id);
        let smallDate: Date = new Date(1990, 0, 1);
        let filteredLog: ImpersonationLogModel[] = impersonationLog.filter(value => new Date(value.endTime) <= smallDate);
        for (const item of filteredLog) {
          item.EndTime = new Date();
          await this.mszImpersonationService.SaveImpersonationLog(item);
        };
        //this.authUser.cache.impersonator = undefined;
        this.citadelUserActionsService.clear();
        this.citadelUserActionsService.addLink('View Client Profile', ['/MSZ/mszimpersonate']);
        this.mszImpersonationService.isImpersonating = false;
      }
    }

    //this.getMenuItems();

    var done: boolean = await this.loadClientsAndAccounts(this.authUser.cache.id);

    if (done) {
      this.getMenuItems();
    }

    if (impersonateClient != "") {
      this.router.navigateByUrl("MSZ");
    }
    else if (switchBack) {
      if (this.isInCitazen) {
        this.router.navigateByUrl("MSZ/WebAdminComponent/WebAdmin");
      }
      else {
        this.router.navigateByUrl("MSZ");
      }
    }
  }

  hasPermission(clientID: string, code: string): any {
    return new Promise<any>(async (resolve, reject) => {
      this.webService.get('WA.WebProfile/hasPermission', { clientID: clientID, code: code }).subscribe((result: any) => {
        resolve(result);
      });

    });
  }



  removeH4Component(componentList): any[] {
    for (let i = 0; i < componentList.length; i++) {
      if (componentList[i].permissionCode.includes('Financial related')) {
        componentList.splice(i, 1);
      }

      if (componentList[i].permissionCode.includes('Upload document to advisor')) {
        componentList[i].component = 'Upload document';
      }

      if (componentList[i].permissionCode.includes('ChangeOtpNumber')) {
        componentList.splice(i, 1);
      }

      if (componentList[i].permissionCode.includes('Advisor detail')) {
        componentList.splice(i, 1);
      }


      if (componentList[i].permissionCode.includes('Fiduciary')) {
        componentList.splice(i, 1);
      }





      if (componentList[i].permissionCode.includes('Subscription Preference')) {
        componentList.splice(i, 1);
      }

      if (componentList[i].permissionCode.includes('Subscription Hobbies and Interest')) {
        componentList.splice(i, 1);
      }


       if (componentList[i].permissionCode.includes('Financial Related') || componentList[i].permissionCode.includes('Financial related')) {
         componentList.splice(i, 1);
       }

      // if (componentList[i].permissionCode.includes('Investment Related')) {
      //   componentList.splice(i, 1);
      // }

    }

    return componentList;

  }




  changeH4Component(menuitem: CitadelMenuItem): CitadelMenuItem {


    if (menuitem.title.includes('Upload document to advice team')) {
      menuitem.title = 'Upload document';
    }

    if (menuitem.title.includes('Advisor detail')) {
      menuitem.title = 'H4 Detail';
    }


    if (menuitem.title.includes('Citadel Support')) {
      menuitem.title = 'H4 Support';
    }




    return menuitem;


  }

  isH4Branding = false;
  checkH4Branding() {

    this.isH4Branding = false;

    var theme: string = localStorage.getItem('currentTheme');
    if (theme.includes("H4 CI")) {
      this.isH4Branding = true;
    }





  }


  removeMSZComponent(componentList): any[] {

    for (let i = 0; i < componentList.length; i++) {

      if (componentList[i].permissionCode.includes('Forms')) {
        componentList.splice(i, 1);
      }

    }

    return componentList;
  }

  async getMenuItems() {

    this.checkH4Branding();
    let filteredPermissions: string[] = [];
    let authPermissionList = await this.loadAuthPermissions();
    if (authPermissionList != undefined) {
      if (this.authUser && this.authUser.cache) {
        let cID = this.authUser.cache.id;
        let componentList = await this.loadComponentList(cID, false);

        if (this.isH4Branding) {
          componentList = this.removeH4Component(componentList);
        }else{
          componentList = this.removeMSZComponent(componentList);
        }


        if (authPermissionList != undefined && authPermissionList.length > 0 && componentList != undefined && componentList.length > 0) {
          for (const permission of authPermissionList) {
            if (componentList.filter(val => val.permissionCode.toLowerCase() == permission.permissionCode.toLowerCase()).length > 0) {
              filteredPermissions.push(permission.id);
            }
          };
          if (this.authUser.cache) {
            //if (!this.authUser.cache.hasRole('Super User') && !this.authUser.cache.hasRole('MSZ Support User') && !this.isInCitazen) {
            if (!this.isInCitazen) {
              this.citadelMenuService.clearFilter();
              if (filteredPermissions.length > 0) {
                let filterID = this.citadelMenuService.addFilter(menuItem => {

                  if (this.isH4Branding) {
                    menuItem = this.changeH4Component(menuItem);
                  }

                  return filteredPermissions.includes(menuItem.id);
                });
              }
            }
          }
        }
      }
      else {
        this.citadelNotificationService.info("Error on filtering menu");
      }
    }
  }

}


