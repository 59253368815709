import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CitadelAuthenticatedUserGuard } from '@citadel/core';
import { addCitazenRoutes } from '@citadel/citazen';
import { AppHomeComponent } from './app-home/app-home.component';


const routes: Routes = [{
  path: '',
  component: AppHomeComponent,
  canActivate: [CitadelAuthenticatedUserGuard],
  children: [{
    path: 'MSZ',
    loadChildren:  () => import ('../../projects/citadel-msz/src/lib/citadel-msz.module').then(m => m.CitadelMSZModule)
  }, {
    path: '',
    redirectTo: 'MSZ',
    pathMatch: 'full'
  }],
}];

// const config: ExtraOptions = {
//   onSameUrlNavigation: 'reload'
// };

@NgModule({
  imports: [RouterModule.forRoot(addCitazenRoutes(routes))],
  exports: [RouterModule]
})
export class AppRoutingModule { }
