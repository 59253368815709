import { Injectable } from '@angular/core';
import { CitadelWebService, CitadelNotificationService } from '@citadel/core';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { MarketTrendModel } from '../models/MarketTrendModel';
import { CitadelWebClientService } from './../citadel-webadmin/citadel-web-client.service'
import { ActivityLogModel, enumLogAction, enumLogEventLevel } from '../models/ActivityLogModel';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable({
  providedIn: 'root'
})
export class CitadelMszService {
  constructor(private deviceDetectorService: DeviceDetectorService, private clientServ: CitadelWebClientService, private webServ: CitadelWebService, private notificationServ: CitadelNotificationService) { }


  public lookupList: {
    cache: any[],
    sub: BehaviorSubject<any[]>,
    obs: Observable<any[]>
  } = {
      cache: undefined,
      sub: undefined,
      obs: undefined
    };

  public lookupTyrusList: {
    cache: any[],
    sub: BehaviorSubject<any[]>,
    obs: Observable<any[]>
  } = {
      cache: undefined,
      sub: undefined,
      obs: undefined
    };

  public globalGridIsPrimary: boolean = false;
  public globalFileIsPrimary: boolean = true;


  loadLookups() {
    if (!this.lookupList.obs) {
      this.lookupList.sub = new BehaviorSubject<any[]>(this.lookupList.cache);
      this.lookupList.obs = this.lookupList.sub.asObservable();
    }
    if (this.lookupList.cache == undefined) {
      this.webServ.get('Msz.Structure/loadLookups').subscribe((lookups: any) => {
        this.lookupList.cache = [];
        this.lookupList.cache = lookups;
        this.lookupList.sub.next(this.lookupList.cache);

      });
    }
  }

  loadTyrusLookups() {
    if (!this.lookupTyrusList.obs) {
      this.lookupTyrusList.sub = new BehaviorSubject<any[]>(this.lookupTyrusList.cache);
      this.lookupTyrusList.obs = this.lookupTyrusList.sub.asObservable();
    }
    if (this.lookupTyrusList.cache == undefined) {
      this.webServ.get('Msz.Structure/loadTyrusLookups').subscribe((lookups: any) => {
        this.lookupTyrusList.cache = [];
        this.lookupTyrusList.cache = lookups;
        this.lookupTyrusList.sub.next(this.lookupTyrusList.cache);

      });
    }
  }

  ConvertCurrencyUsingAbbr(amount: number, currencyFrom: string, currencyTo: string, date:Date)
  {
    var result : number = amount;

    if (this.lookupTyrusList.cache != undefined && currencyFrom && currencyTo)
    {
      let lkpSharedCurrency: any[] = [];
      lkpSharedCurrency = this.lookupTyrusList.cache["lkpSharedCurrency"];
      
      var fromCurrency = lkpSharedCurrency.find(item => item.abbreviation == currencyFrom.toUpperCase());
      var toCurrency = lkpSharedCurrency.find(item => item.abbreviation == currencyTo.toUpperCase());

      if (fromCurrency && toCurrency)
      {
        result = this.ConvertCurrency(amount, fromCurrency.lid, toCurrency.lid, date)
      }
    }
    
    return result;
  } 

  ConvertCurrency(amount: number, currencyFromL:number, currencyToL:number, date:Date)
  {
    var result : number = 0.0000;

    if (currencyFromL == currencyToL)
    {
      result = amount;
    }
    else
    {
      if (this.lookupTyrusList.cache != undefined)
      {
        let lkpSharedCurrencyValues: any[] = [];
        lkpSharedCurrencyValues = this.lookupTyrusList.cache["lkpSharedCurrencyValues"];

        var fromRelativeValues = lkpSharedCurrencyValues.filter(item => item.currencyL == currencyFromL && new Date(item.dateValid) <= new Date(date));
        var toRelativeValues = lkpSharedCurrencyValues.filter(item => item.currencyL == currencyToL && new Date(item.dateValid) <= new Date(date));

        if (fromRelativeValues.length > 0 && toRelativeValues.length > 0)
        {
          fromRelativeValues.sort((a,b) => Date.parse(b.dateValid) - Date.parse(a.dateValid));
          toRelativeValues.sort((a,b) => Date.parse(b.dateValid) - Date.parse(a.dateValid));

          result = (amount / fromRelativeValues[0].relativeValue) * toRelativeValues[0].relativeValue;
        }
      }
    }
    return result;
  }

  GetDeviceType() {
    let result: string = "desktop";

    if(this.deviceDetectorService){
      if (this.deviceDetectorService.isMobile()) {
        result = "mobile";
      }
  
      if (this.deviceDetectorService.isTablet()) {
        result = "tablet";
      }
    }


    return result;
  }

  isTablet() {
    return this.deviceDetectorService.isTablet();
  }

  isMobile() {
    if (this.GetDeviceType() == "desktop") {
      return false;
    }
    else {
      return true;
    }
  }

  GetMarketComments(companyID: string): Promise<MarketTrendModel[]> {
    return this.webServ.get('Msz.MarketTrend/getMarketComments', { CompanyID: companyID }).pipe(tap((items: MarketTrendModel[]) => {
      let marketTrends: MarketTrendModel[] = items;
      return marketTrends;
    })).toPromise();
  }

  DownloadFile(blob: any, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async writeToMSZLogSync(action: enumLogAction, comments: string) {
    let model: ActivityLogModel = new ActivityLogModel();
    model.LogLevel = enumLogEventLevel.Information;
    model.Action = action;
    model.Exception = "";
    model.Comments = comments;
    await this.MSZLog(model);
  }


  writeToMSZLog(action: enumLogAction, comments: string) {
    let model: ActivityLogModel = new ActivityLogModel();
    model.LogLevel = enumLogEventLevel.Information;
    model.Action = action;
    model.Exception = "";
    model.Comments = comments;
    this.MSZLog(model);
  }

  MSZLog(model: ActivityLogModel): any {
    return new Promise<any>(async (resolve, reject) => {
      let isInCZN: boolean = false;
      isInCZN = await this.clientServ.checkInCitazen();
      if (isInCZN == false)
      {
        let clientID: string = "";
        if (this.clientServ.authUser && this.clientServ.authUser.cache) {
          clientID = this.clientServ.authUser.cache.id;
        }
        model.ClientID = clientID;
        this.webServ.post('Msz.ActivityLog/mszLog', model).subscribe((result: any) => {
          resolve(true);
        });
      }
      else
      {
        resolve(true);
      }
    });
  }

  saveMarketTrends(model: MarketTrendModel): any {
    return new Promise<any>(async (resolve, reject) => {
      let trends: MarketTrendModel[] = [];
      trends.push(model);
      this.webServ.post('Msz.MarketTrend/storeMarketTrends', trends).subscribe((result: any) => {
        resolve(true);
      });
    });
  }

  getDisclaimer(): any {
    return new Promise<any>(async (resolve, reject) => {
      this.webServ.get('Msz.Structure/getDisclaimer').subscribe((result: any) => {
        resolve(result);
      });
    });
  }


  async getMarketComment(companyID: string) {
    let result: MarketTrendModel[] = await this.GetMarketComments(companyID);
    if (result && result.length > 0)
    {
      result.sort((a, b) => (a.EffectiveDate > b.EffectiveDate) ? 1 : -1);
      return result[0].ContentHTML;
    }
    else
    {
      return '';
    }
  }

  getEmailAddressesByClient(id): Observable<any[]> {
    let result = this.webServ.post<any[]>('Crm.Email/' + id, null).pipe(map((obj) => {
      let objs = obj;
      return objs;
    }));
    return result;
  }

  getPhoneNumbersByClient(id): Observable<any[]> {
    let result = this.webServ.post<any[]>('Crm.Telephone/' + id, null).pipe(map((data) => {
      let objs = data;
      return objs;
    }));
    return result;
  }
}
