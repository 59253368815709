import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CitadelUserService, CitadelAuthService, CitadelLoaderService, CitadelSilentImpersonationService } from '@citadel/core';
import { CitadelCitazenService, CitadelUserActionsService, CitadelUserNotificationService } from '@citadel/citazen';
import { CitadelWebClientService } from '../../projects/citadel-msz/src/lib/citadel-webadmin/citadel-web-client.service';
import { Subscription } from 'rxjs';
import { CitadelHeaderSectionsService, CitadelMenuHeaderSection,  CitadelUserHeaderSection, CitadelNotificationHeaderSection } from '@citadel/controls';
import { CitadelThemeService } from '@citadel/styles';

@Component({
  selector: 'cmapp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('user') userTemplate: TemplateRef<any>;
  @ViewChild('menu') menuTemplate: TemplateRef<any>;
  @ViewChild('notification') notificationTemplate: TemplateRef<any>;
  private authCheckSubscription: Subscription;
  authenticationHasBeenChecked = false;
  userSubscription: Subscription;
  constructor(private silentImpersonationService: CitadelSilentImpersonationService,  private citadelLoaderService: CitadelLoaderService,  private userNotification: CitadelUserNotificationService,private citazen: CitadelCitazenService, private userActions: CitadelUserActionsService, public user: CitadelUserService, private webClientService: CitadelWebClientService, private auth: CitadelAuthService, private headerSections: CitadelHeaderSectionsService, private menuHeaderSection: CitadelMenuHeaderSection, private userHeaderSection: CitadelUserHeaderSection, private notificationHeaderSection: CitadelNotificationHeaderSection, private themes: CitadelThemeService) {
    this.citazen.setApplicationName('MySecureZone');
    //this.themes.setCurrentTheme('MySecureZone Default');
    themes.setThemeFromReferrer();
    //this.themes.setCurrentTheme('Assupol Dark')
    
    this.citadelLoaderService.disable();
    this.citazen.startAuthentication();
    userNotification.addMapper(item => {
      if (!item.description && item.partyNotificationMessage) {
        item.isArchive = true; //Mark as Archived
      }
      return item;
    });
    userNotification.setCustomFilter(entry => !entry.notificationCategoryDescription.toUpperCase().includes('TASK'));
    
    this.authCheckSubscription = this.auth.isAuthenticated.subscribe(isAuthenticated => {
      if (isAuthenticated && !this.userSubscription) {
        this.userSubscription = this.user.user.subscribe(userValue => {
          //NOTIFICATIONS this.webClientService.clearClientNotifications();
          //NOTIFICATIONS this.webClientService.getClientNotifications(userValue.id);
          if (userValue.hasPermission('Impersonate-MSZ') ) {
            this.userActions.clear();
            this.userActions.addLink('View Client Profile', ['/MSZ/mszimpersonate']);
            this.userSubscription.unsubscribe();
          }
          //NOTIFICATIONS
          // if (userValue.impersonator)
          // {
          //   this.silentImpersonationService.cancel();
          // }
          
        });
      }
      this.authenticationHasBeenChecked = true;
    });
  }
  ngOnDestroy() {
    if (this.authCheckSubscription) {
      this.authCheckSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    setTimeout(() => this.addHeaderSections());
  }
  private addHeaderSections() {
    this.headerSections.addHeaderSection(this.notificationHeaderSection, this.notificationTemplate);
    this.headerSections.addHeaderSection(this.userHeaderSection, this.userTemplate);
    this.headerSections.addHeaderSection(this.menuHeaderSection, this.menuTemplate);
  }
}
